<template>
    <div class=" getstarted_boxes d-none">

        <div class="col-md-3 box_item completed">
            <div @click="gettingStarted('emailVerification')" class="box_inner">
                <div class="heading">
                    <p>Confirm Email</p>
                </div>
                <div class="content">
                    <div class="icon">
                        <i class="far fa-envelope"></i>
                    </div>
                    <div class="status">
                        <p>Completed</p>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-md-3 box_item">
            <div @click="gettingStarted('createBrand')" class="box_inner">
                <div class="heading">
                    <p>Create Brand</p>
                </div>
                <div class="content">
                    <div class="icon">
                        <i class="fab fa-bandcamp"></i>
                    </div>
                    <div class="status">
                        <p>Uncompleted</p>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-md-3 box_item">
            <div @click="gettingStarted('createCTA')" class="box_inner">
                <div class="heading">
                    <p>Create CTA</p>
                </div>
                <div class="content">
                    <div class="icon">
                        <i class="far fa-location-arrow"></i>
                    </div>
                    <div class="status">
                        <p>Uncompleted</p>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-md-3 box_item">
            <div @click="gettingStarted('createLink')" class="box_inner">
                <div class="heading">
                    <p>Create Link</p>
                </div>
                <div class="content">
                    <div class="icon">
                        <i class="far fa-link"></i>
                    </div>
                    <div class="status">
                        <p>Uncompleted</p>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
<script>
export default ({
  name: 'onboarding-getting-started-steps'
})
</script>
